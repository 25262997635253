import RoutesApp from './routes';
import GlobalStyle from '../src/styles/global';

function App() {
    return (
        <>
            <GlobalStyle />
            <RoutesApp />
        </>
    );
}

export default App;
